<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
            <b-row>
                <b-col>
                    <h4>Toolbox Talks Compliance Report</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <label>Report according to</label>
                    <b-form-select v-model="selectedParty" @change="changePartySelection()">
                        <b-form-select-option v-for="(item, index) in reportOptions" :key="index" :value="item">{{item}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
          <b-row>
            <b-col cols="4" v-if="selectedParty === 'Supervisor'">
              <label>Supervisor</label>
                <b-input-group>
                <b-form-input v-model="search.supervisor.name" :disabled="true"></b-form-input>
                <b-input-group-append>
                    <b-button size="sm"  text="Button" variant="secondary" @click="openAddSupervisorModal">Search</b-button>
                </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col cols="4" v-if="selectedParty === 'Company'">
              <label>Business Unit</label>
                <b-input-group>
                  <b-form-select v-model="search.businessUnit">
                        <b-form-select-option v-for="(item, index) in businessUnitList" :key="index" :value="item">{{item.value}}</b-form-select-option>
                    </b-form-select>
                </b-input-group>
            </b-col>
            <b-col cols="4">
              <label>Date From</label>
              <b-form-datepicker v-model="search.dateCreatedFrom"></b-form-datepicker>
            </b-col>
            <b-col cols="4">
              <label>Date To</label>
              <b-form-datepicker v-model="search.dateCreatedTo"></b-form-datepicker>
            </b-col>
          </b-row>
          <hr class="mx-3" />
          <b-row>
            <b-col class="text-right">
              <b-button variant="primary" squared class="text-right" @click="exportDocument()">Export</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="fetchingData === true">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-center">
              <h6 class="font-weight-bold"> Fetching data. This might take a while</h6>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    
        <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
            
            <b-row>
                <b-col cols="4">
                    <label>Name</label>
                    <b-form-input v-model="user.name"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Surname</label>
                    <b-form-input v-model="user.surname"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Email</label>
                    <b-form-input v-model="user.email"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Cancel</b-button>
                    <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col class="12">
                    <b-table striped hover 
                            :items="tableDataUsers.dataSource" 
                            :fields="tableDataUsers.tableColumns"
                            :busy="tableDataUsers.isLoading" 
                            @row-clicked="chooseUser"
                            :per-page="tableDataUsers.resultsPerPage"
                            id="userSearch"
                            :current-page="tableDataUsers.currentPage" sort-icon-left>

                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                                <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
          
            <b-row align-h="center" >
                <b-pagination
                    v-model="tableDataUsers.currentPage"
                    :total-rows="userRows"
                    :per-page="tableDataUsers.resultsPerPage"
                    aria-controls="userSearch"
                ></b-pagination>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  data:() => ({
    selectedParty: 'Supervisor',
    reportOptions: ['Supervisor', 'Company'],
    search: {
      supervisor: {name: null},
      description: null,
      dateCreatedFrom: null,
      dateCreatedTo: null,
      businessUnit: null
    },
    businessUnitList: [],
    jsonData: [],
    fetchingData: false,
        user: {
            name: null,
            surname: null,
            email: null,
            roleID: 0
        },
        tableDataUsers: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                label: 'Name',
                key: 'name',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Surname',
                key: 'surname',
                sortable: true,
                tdClass: '',
                },
                {
                label: '',
                key: 'actions',
                sortable: false,
                tdClass: ''
                }
            ]
        },
  }),
  created(){
    this.setBreadcrumb([
        {
            text: 'Reports'
        },
        {
            text: 'Compliance Report',
            active: true
        }
    ])
    this.getCompanyList()
    .then((res) => {
      this.businessUnitList = res.data
    })
  },
  methods:{
    ...mapActions(['downloadComplianceReport', 'downloadCompanyComplianceReport', 'searchUsersRequest','getCompanyList']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage']),
    
    exportDocument(){
      this.fetchingData = true
      let request = []
      console.log("exportDocument")

      console.log("businessUnit",this.search.businessUnit)
      if(this.search.businessUnit != null){
          request.push({"key":"businessUnitId","value": this.search.businessUnit.key})
      }
      
      
      if(this.search.supervisor.name != null){
        console.log("supervisorid",this.search.supervisorid)
          request.push({"key":"supervisorid","value": this.search.supervisor.userId})
      }
      
      
      console.log("fromDate",this.search.dateCreatedFrom)
      if(this.search.dateCreatedFrom != null){
          request.push({"key":"fromDate","value": this.search.dateCreatedFrom})
      }
      
      console.log("toDate",this.search.dateCreatedTo)
      if(this.search.dateCreatedTo != null){
          request.push({"key":"toDate","value": this.search.dateCreatedTo})
      }

      console.log("selectedParty",this.selectedParty)
      request.push({"key":"selectedParty","value": this.selectedParty})

console.log("request",request)
      this.$store.commit('setDownloadRequest', request)
      if (this.selectedParty == "Company")
      {
        this.downloadCompanyComplianceReport()
        .then((res) => {
          this.fetchingData = false

          if (res.data !== '') {
            const linkSource = `data:${res.data.contentType};base64,${res.data.base64Data}`;
            const downloadLink = document.createElement("a");

            downloadLink.href = linkSource;
            downloadLink.download = res.data.fileName;
            downloadLink.click();
          }
          else{
            this.addWarningMessage('No data available to download')
          }
        })
        .catch(() => {
          this.fetchingData = false
        })
      }
      else
      {
        this.downloadComplianceReport()
        .then((res) => {
          this.fetchingData = false

          if (res.data !== '') {
            const linkSource = `data:${res.data.contentType};base64,${res.data.base64Data}`;
            const downloadLink = document.createElement("a");

            downloadLink.href = linkSource;
            downloadLink.download = res.data.fileName;
            downloadLink.click();
          }
          else{
            this.addWarningMessage('No data available to download')
          }
        })
        .catch(() => {
          this.fetchingData = false
        })
    }
    },
    chooseUser(rowItem){
      this.search.supervisor = rowItem
      this.closeSearchUserModal()
    },
    changePartySelection(){
      this.search = {
        supervisor: {name: null},
        description: null,
        dateCreatedFrom: null,
        dateCreatedTo: null
      }
    },
    openAddSupervisorModal(){
        this.$bvModal.show('search-user-modal')
        this.user.roleId = 1
        this.doSearchUsers()
    },
    closeSearchUserModal(){
        this.$bvModal.hide('search-user-modal')
    },
    clearUsersFilter(){
        this.user = {
            name: null,
            surname: null,
            email: null
        }
    },
    doSearchUsers(){
        this.tableDataUsers.isLoading = true
  
        let request = []
        
        if (this.user.name != null) {
            request.push({'key': 'name', 'value': this.user.name})
        }
        if (this.user.surname != null) {
            request.push({'key': 'surname', 'value': this.user.surname})
        }
        if (this.user.email != null) {
            request.push({'key': 'email', 'value': this.user.email})
        }
        if (this.user.roleId != null) {
            request.push({'key': 'fkroleId', 'value': this.user.roleId})
        }
        
        this.$store.commit('setUserSearchRequest', request)
        
        this.searchUsersRequest()
        .then((request) => {
            this.tableDataUsers.dataSource = request.data
            
            this.jsonData = this.tableDataUsers.dataSource.map(v => ({
            name: v.name,
            surname: v.surname,
            contactNumber: v.contactNumber,
            email: v.email,
            role: v.role,
            lastLoginDate: v.lastLoginDate === null ? '' : new Date(v.lastLoginDate).toLocaleDateString('en-ZA')
            }))
            
            this.tableDataUsers.isLoading = false
            this.state  = 'initialize'
        })
        .catch( () => {
            this.tableDataUsers.isLoading = false
        })
    },
  },
  computed: {
      
        userRows() {
            return this.tableDataUsers.dataSource.length
        },
  }
}
</script>

<style scoped>

</style>